<template>
    <div class="main-bg">
        <div v-show="loading" id="semiTransparenDiv"></div>
        <div class="main-bg-wrapper">
            <div class="logo">
                <img :src="require('@/assets/images/logo-img.png')" alt="imageName">
                <img :src="require('@/assets/images/logo-name.png')">
            </div>
            <div class="form-table">
                <div class="form-inner">
                    <p>Change password</p>
                </div>
                <div class="form">
                    <div class="form-group">
                        <label for="exampleInputPassword1">Current Password</label>
                        <input :type="currentPwdFieldType" class="form-control" id="exampleInputPassword1" placeholder="Enter Password" v-model="current_pwd">
                        <i :class="['fa ', currentPwdFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye']" aria-hidden="true" @click="switchcurrentPwd()"></i>
                        <span v-if="current_pwd_error" class="error_alert">{{current_pwd_error_message}}</span>

                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword2">New Password</label>
                        <input :type="newPwdFieldType" class="form-control" id="exampleInputPassword2" placeholder="Enter Password" v-model="new_pwd"    >
                        <i :class="['fa ', newPwdFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye']" aria-hidden="true" @click="switchnewPwd()"></i>
                        <span v-if="new_pwd_error" class="error_alert">{{new_pwd_error_message}}</span>

                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword3">Confirm Password</label>
                        <input :type="cnfrmPwdFieldType" class="form-control" id="exampleInputPassword3" placeholder="Enter Password" v-model="confirm_pwd" >
                        <i :class="['fa ', cnfrmPwdFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye']" aria-hidden="true" @click="switchcnfrmPwd()"></i>
                        <span v-if="cnfrm_pwd_error" class="error_alert">{{cmfrm_pwd_error_message}}</span>

                    </div>
                </div>
                <div class="button">
                    <a href="javascript:void(0)" @click="changePassword()"><button>SAVE AND CONTINUE</button></a>
                </div>
            </div>
        </div>
    </div>
  
</template>

<script>
    const axios = require('axios').default;
    const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'

    export default {
        name: 'changePassword',
        data () {
            return {
                current_pwd:'',
                new_pwd:'',
                confirm_pwd:'',
                newPwdFieldType:"password",
                cnfrmPwdFieldType:"password",
                currentPwdFieldType:"password",
                loading: false,
                errors: [],
                current_pwd_error:false,
                new_pwd_error:false,
                cnfrm_pwd_error:false,
                new_pwd_error_message:"",
                cmfrm_pwd_error_message:"",
                current_pwd_error_message:""

            }
            },
        methods: {

            switchcurrentPwd(){
                this.currentPwdFieldType = this.currentPwdFieldType === "password" ? "text":"password";
            },
            switchnewPwd(){
                this.newPwdFieldType = this.newPwdFieldType === "password" ? "text":"password";
            },
            switchcnfrmPwd(){
                this.cnfrmPwdFieldType = this.cnfrmPwdFieldType === "password" ? "text":"password";
            },

            async changePassword(){
                this.errors=[]
                if (this.validate()) {
                    this.loading= true;
                    try{
                        const data =  { "old_password": this.current_pwd,"new_password":this.new_pwd };
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        const response =await  axios.put(`${baseUrl}/auth/change_password`, data,{headers})
                        this.loading = false;
                        // console.log(response);
                        await this.$toasted.success(response.data.detail);
                        await this.$router.push('/brands-listing')
                    }
                    catch (e) {
                        this.loading = false;
                        var i;
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                    }
                }

            },

            validate(){
                this.current_pwd_error=false
                this.new_pwd_error=false
                this.cnfrm_pwd_error=false
                if ((!this.current_pwd) || (this.current_pwd.trim().length<=0)) {
                    this.current_pwd_error=true
                    this.current_pwd_error_message="Please provide current password!"
                    this.errors.push("Please provide current password!")
                    this.$toasted.error('Please provide current password!');
                }
                if ((!this.new_pwd) || (this.new_pwd.trim().length<=0)) {
                    this.new_pwd_error=true
                    this.new_pwd_error_message="Please provide new password!"
                    this.errors.push("Please provide new password!")
                    this.$toasted.error('Please provide new password!');
                }
                if ((!this.confirm_pwd) || (this.confirm_pwd.trim().length<=0)) {
                    this.cnfrm_pwd_error=true
                    this.cmfrm_pwd_error_message="Please provide confirm password!"
                    this.errors.push("Please provide confirm password!")
                    this.$toasted.error('Please provide confirm password!');
                }

                if ((this.confirm_pwd.trim().length) && (this.confirm_pwd.trim().length<8) ){
                    this.cnfrm_pwd_error=true
                    this.cmfrm_pwd_error_message="Your password must contain at least 8 characters!"
                    this.errors.push("Your password must contain at least 8 characters!")
                    this.$toasted.error('Your password must contain at least 8 characters!');
                }

                if ((this.new_pwd.trim().length) && (this.new_pwd.trim().length<8) ){
                    this.new_pwd_error=true
                    this.new_pwd_error_message="Your password must contain at least 8 characters!"
                    this.errors.push("Your password must contain at least 8 characters!")
                    this.$toasted.error('Your password must contain at least 8 characters!');
                }

                if (Number(this.new_pwd)) {
                    this.new_pwd_error=true
                    this.new_pwd_error_message="Your password can't be entirely numeric!"
                    this.errors.push("Your password can't be entirely numeric.")
                    this.$toasted.error('Your password cannot be entirely numeric.');
                }

                if (Number(this.confirm_pwd)) {
                    this.cnfrm_pwd_error=true
                    this.cmfrm_pwd_error_message="Your password can't be entirely numeric!"
                    this.errors.push("Your password can't be entirely numeric.")
                    this.$toasted.error('Your password cannot be entirely numeric.');
                }


                if (this.new_pwd != this.confirm_pwd){
                    this.errors.push("New and Confirm password must be identical!")
                    this.$toasted.error('New and Confirm password must be identical!');
                }

                if (!this.errors.length) {
                    return true;
                } 
            },

            
        },
        mounted(){

        },
        created(){

        },
       
    }

</script>


<style>

</style>